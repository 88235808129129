import { Loading } from 'react-admin';
import Loadable from 'react-loadable';


const logTaskList = Loadable({ loader: () => import('./logTaskList'), loading: Loading });
const logTaskShow = Loadable({ loader: () => import('./logTaskShow'), loading: Loading });


export default { 
  list: logTaskList,
  show: logTaskShow,
};
import { jwtDecode as decodeJwt } from 'jwt-decode';
import axios from 'axios';

const url = process.env.REACT_APP_AUTH_URL; //"http://192.168.71.155:4123/auth/login";
const TOKEN_KEY = 'token';

export const authProvider = {
    login: async ({ username, password }) => {
        try {
            const data = { username, password };
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            const res = await axios.post(url, data, options);
            if (res.data && res.data.token) {
                const token = res.data.token;
                localStorage.setItem(TOKEN_KEY, token);
                // Optionally remove sessionStorage if not needed
                sessionStorage.removeItem(TOKEN_KEY);
            } else {
                throw new Error('No token received from the server.');
            }
        } catch (error) {
            const message = error.response?.data?.message || error.message || 'An error occurred during login';
            throw new Error(message);
        }
    },

    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(TOKEN_KEY);
        return Promise.resolve();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem(TOKEN_KEY);
            sessionStorage.removeItem(TOKEN_KEY);
            return Promise.reject('Unauthorized or Forbidden');
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            try {
                const decodedToken = decodeJwt(token);
                
                const now = Math.floor(Date.now() / 1000);
                if (decodedToken.exp < now) {
                    return authProvider.logout().then(() => Promise.reject('Token expired'));
                }
                return Promise.resolve();
            } catch (error) {
                return authProvider.logout().then(() => Promise.reject('Invalid token'));
            }
        }
        return Promise.reject('No token found');
    },

    getPermissions: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            try {
                const { sub } = decodeJwt(token);
                return sub?.roles ? Promise.resolve(sub.roles) : Promise.reject('No roles found in token');
            } catch (error) {
                return Promise.reject(error.message || 'Error decoding token');
            }
        }
        return Promise.reject('No token found');
    },
};

export default authProvider;

import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import { Datagrid, DateField, FunctionField, ReferenceField, TextField, useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// Query function for trainings to be deployed today 
const fetchTrainings = async (dataProvider, today, col) => {
    const { data } = await dataProvider.getList('training', {
        filter: { [col]: `${today}%` }, // Filter for today's date using LIKE operator
        sort: { field: col, order: 'ASC' },
        pagination: { page: 1, perPage: 10 },
    });
    return data;
};

// Query function for trainings to be deployed today 
const fetchDeployedTrainings = async (dataProvider) => {
    const { data } = await dataProvider.getList('training', {
        filter: { is_deployed: true }, // Only fetch deployed trainings
        sort: { field: 'start_dt_cest', order: 'ASC' }, // Sort by start date
        pagination: { page: 1, perPage: 10 },
    });
    return data;
};

// Query function for trainings to be cleand today 


const fetchClassrooms = async (dataProvider) => {
    const rooms = await dataProvider.getList('rlab/room');

    return rooms;
};

const Dashboard = () => {
    const dataProvider = useDataProvider();
    const today = moment().format('YYYY-MM-DD');
    const navigate = useNavigate();

    // Use react-query's useQuery hook to retreive training to be deployed today 
    const { data: trainings = [], isLoading, error } = useQuery(
        ['start_dt_cest', today], // Query key
        () => fetchTrainings(dataProvider, today, "start_dt_cest"), // Query function
        {
            enabled: !!dataProvider, // Only fetch if dataProvider is available
        }

    );
     // Use react-query's useQuery hook to retreive deployed training 
    const { data: training_dep = [], isLoadingD, errorD } = useQuery(
        ['deployed_trainings'],
        () => fetchDeployedTrainings(dataProvider),
        { enabled: !!dataProvider }
    );
    // Use react-query's useQuery hook to retreive training to be deployed today

    const { data: allrooms = { data: [] }, isLoadingR, errorR } = useQuery(
        ['rlab/room'], // Query key
        () => fetchClassrooms(dataProvider), // Query function
        {
            enabled: !!dataProvider, // Only fetch if dataProvider is available
        }
    );
    const { data: trainings_end = [], isLoadingE, errorE } = useQuery(
        ['end_dt_cest', today], // Query key
        () => fetchTrainings(dataProvider, today, "end_dt_cest"), // Query function
        {
            enabled: !!dataProvider, // Only fetch if dataProvider is available
        }

    );

    if (isLoading || isLoadingR || isLoadingE || isLoadingD ) {
        return <div>Loading...</div>;
    }

    if (error || errorR || errorE || errorD) {
        const genericMessage = "Something bad happend please try later "
        const errorMessage = error ? error.message : errorR ? errorR.message : errorE ? errorE.message : genericMessage;
        return <div>Error: {errorMessage}</div>;
    }



    const handleRowClick = (id) => {
        // Execute custom function here
        navigate(`/training/${id}/show`); // Example of navigating to a show page
    };

    return (
        <Card variant="outlined" sx={{ minWidth: 275, boxShadow: 3 }}>
            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <Typography
                        variant="h5"
                        component="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#1976d2', // Material-UI primary color
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Deployed Training Sessions :
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </Box>
                {training_dep.length > 0 ? (
                    <Datagrid
                        data={training_dep}
                        rowClick={(id) => handleRowClick(id)}
                        sort={{ field: 'start_dt_cest', order: 'DESC' }}
                        sx={{ '& .MuiDataGrid-cell': { fontSize: '0.875rem' } }}
                    >
                        <TextField label="Title" source="title" />
                        <TextField label="Locator" source="locator" />
                        <TextField label="Instructor" source="instructor" />
                        <DateField label="Start Date" source="start_dt_cest" showTime />
                        <DateField label="End Date" source="end_dt_cest" showTime />
                        <TextField label="Part Location" source="part_location" />
                        <FunctionField label="Check status"
                            render={
                                record => `${record.was_checked ? '✅' : '❌'}`
                            }
                        />
                        <FunctionField label="Deploy status"
                            render={
                                record => `${record.is_deployed ? '✅' : '❌'}`
                            }
                        />
                        <FunctionField label="Archive status"
                            render={
                                record => `${record.is_archived ? '✅' : '❌'}`
                            }
                        />
                    </Datagrid>
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No Deployed training.
                    </Typography>
                )}
            </CardContent>
            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <Typography
                        variant="h5"
                        component="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#1976d2', // Material-UI primary color
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Training Sessions Scheduled for Deployement Today:
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </Box>
                {trainings.length > 0 ? (
                    <Datagrid
                        data={trainings}
                        rowClick={(id) => handleRowClick(id)}
                        sort={{ field: 'start_dt_cest', order: 'DESC' }}
                        sx={{ '& .MuiDataGrid-cell': { fontSize: '0.875rem' } }}
                    >
                        <TextField label="Title" source="title" />
                        <TextField label="Locator" source="locator" />
                        <TextField label="Instructor" source="instructor" />
                        <DateField label="Start Date" source="start_dt_cest" showTime />
                        <DateField label="End Date" source="end_dt_cest" showTime />
                        <TextField label="Part Location" source="part_location" />
                        <FunctionField label="Check status"
                            render={
                                record => `${record.was_checked ? '✅' : '❌'}`
                            }
                        />
                        <FunctionField label="Deploy status"
                            render={
                                record => `${record.is_deployed ? '✅' : '❌'}`
                            }
                        />
                        
                    </Datagrid>
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No Deployement scheduled for today.
                    </Typography>
                )}
            </CardContent>


            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <Typography
                        variant="h5"
                        component="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#1976d2', // Material-UI primary color
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Training Sessions Scheduled for Cleanup Today:
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </Box>
                {trainings_end.length > 0 ? (
                    <Datagrid
                        data={trainings_end}
                        rowClick={(id) => handleRowClick(id)}
                        sort={{ field: 'start_dt_cest', order: 'DESC' }}
                        sx={{ '& .MuiDataGrid-cell': { fontSize: '0.875rem' } }}
                    >
                        <TextField label="Title" source="title" />
                        <TextField label="Locator" source="locator" />
                        <TextField label="Instructor" source="instructor" />
                        <DateField label="Start Date" source="start_dt_cest" showTime options={{ timeZone: 'UTC' }} />
                        <DateField label="End Date" source="end_dt_cest" showTime options={{ timeZone: 'UTC' }} />
                        <TextField label="Part Location" source="part_location" />
                        <FunctionField label="Archived status"
                            render={
                                record => `${record.is_archived ? '✅' : '❌'}`
                            }
                        />
                    </Datagrid>
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No Cleaning scheduled for today.
                    </Typography>
                )}
            </CardContent>

            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <Typography
                        variant="h5"
                        component="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#1976d3', // Material-UI primary color
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Available Classroom :
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </Box>
                {allrooms.data.length > 0 ? (
                    <Datagrid
                        data={allrooms.data}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        sx={{ '& .MuiDataGrid-cell': { fontSize: '0.875rem' } }}
                    >
                        <TextField label="Name" source="description" />
                        <TextField label="In use" source="name" />
                        <ReferenceField source="training_template" reference="rlab/template">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField label="Training" source="training" />
                    </Datagrid>
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No trainings scheduled for today.
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default Dashboard;


import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

//const apiUrl = process.env.API_URL;
const apiUrl = process.env.REACT_APP_API_URL; //||'http://192.168.71.155:4123'
const httpClient = (url, options = {}) => {
  if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};


const DataProvider = () => {

  return (
    {
      getList: (resource, params) => {
        let url;
        if (params) {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          const filters =
            Object.entries(params.filter).map(([key, value]) => {
              return (`"${key}":"${value}"`);
            });

          const query = {
            filter: filters,
            limit: perPage,
            page: page - 1,
            sort: order,
            field: field,
          };
          url = `${apiUrl}/${resource}?${stringify(query)}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }


        return httpClient(url)
          .then(response => {
            // Extract total count and data from response
            //console.log("total is ",response.json.count);
            //console.log(response.json.data,'response');
            return {
              data: response.json.data,
              total: response.json.count,
            };
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            throw error;
          });

      },

      getOne: (resource, params) => {

        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url).then(({ json }) => {
          //console.log("data json is ",json);
          return {
            data: json ? json : "none",
          }
        });
      },

      // get a list of records based on an array of ids
      getMany: (resource, params) => {

        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`)
          )
        ).then(responses => ({ data: responses.map(response => response.json) }));
      },

      // get the records referenced to another record, e.g. comments for a post
      getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        //console.log("resource found is ", resource);
        let query = {
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
          limit: perPage,
          page: page,
          sortBy: `${field}:${order}`,
        };
        let url = `${apiUrl}/${resource}?${stringify(query)}`;
        if (resource === "training_trainee") {
          const id = params.id;
          url = `${apiUrl}/${resource}/${id}`;
        }

        return httpClient(url).then(({ json }) => {
          if (json.totalResults === 0) {
            console.log(`No ${resource} found`)
          }
          if (resource === "training_trainee") {

            return {
              data: json,
              total: json.length
            }
          }
          //console.log("data found is ", json);
          return {
            data: json.results,
            total: json.totalResults
          };
        });
      },

      create: (resource, params) => {
        //console.log(`@@@ create[${resource},${JSON.stringify(params.data)}]`);
        return httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }))
      },

      update: (resource, params) => {
        //console.log(`@@@ update[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
      },


      // update a list of records based on an array of ids and a common patch
      updateMany: (resource, params) => {
        //console.log(`@@@ updateMany[${resource},${JSON.stringify(params)}]`);
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'PUT',
              body: JSON.stringify(params.data),
            })
          )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
      },

      updateAll: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        //console.log(`@@@ url is ${url}`);
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => ({
          data: json,
        }));

      },

      // delete a record by id

      delete: (resource, params) => {
        //console.log(`@@@ delete[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'DELETE',
        }).then(({ json }) => ({ data: { json } }))
      },
      // delete a list of records based on an array of ids
      deleteMany: (resource, params) => {
        //console.log(`@@@ deleteMany[${resource},${JSON.stringify(params)}]`);
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'DELETE',
            })
          )
        )
          .then(responses => ({ data: responses.map(({ json }) => json.id) }))
      },
      showOne: (resource, params) => {
        //console.log(`@@@ ShowOne [${resource}]`);
        const url = `${apiUrl}/${resource}`;
        console.log(`@@@ url is ${url}`);
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => ({
          data: json,
        }));
      },

    }
  )
};

export default DataProvider;
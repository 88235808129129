import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const TrainingList = Loadable({ loader: () => import('./trainingList'), loading: Loading });
const TrainingShow = Loadable({ loader: () => import('./trainingShow'), loading: Loading });
const TrainingCreate = Loadable({ loader: () => import('./trainingCreate'), loading: Loading });
const TrainingEdit = Loadable({ loader: () => import('./trainingEdit'), loading: Loading });
export default { 
  list: TrainingList,
  show: TrainingShow,
  create: TrainingCreate,
  edit:TrainingEdit,
};
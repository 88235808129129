import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const TzList = Loadable({ loader: () => import('./tzList'), loading: Loading });
const TzCreate = Loadable({ loader: () => import('./tzCreate'), loading: Loading });
const TzShow = Loadable({ loader: () => import('./tzShow'), loading: Loading });
const TzEdit = Loadable({ loader: () => import('./tzEdit'), loading: Loading });
export default { 
  list: TzList,
  show: TzShow,
  create: TzCreate,
  edit:TzEdit,
};
//import logo from './logo.svg';
import './App.css';
import {
  Admin, Resource,  nanoLightTheme,
} from 'react-admin';
import * as React from 'react';
import { authProvider, DataProvider } from './providers';
import { StylesProvider } from '@material-ui/core/styles';
import Gavel from '@mui/icons-material/Gavel';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ReduceCapacityTwoToneIcon from '@mui/icons-material/ReduceCapacityTwoTone';
import TabUnselectedIcon from '@mui/icons-material/TabUnselected';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';

import users from './views/users';
import trainings from './views/trainings';
import rules from './views/rules';
import template from './views/templates';
import Dashboard from './views/Dashboard';
import timezone from './views/timezone';
import logTask from './views/logTask';

process.env.TZ ="Etc/Universal";
const hqDataprovider = DataProvider();
const Hqtitle = " Hq Admin" ;
const App = () => {
  return (
    <StylesProvider injectFirst>     

          <Admin
            title={Hqtitle}
            theme={nanoLightTheme}
            dataProvider={hqDataprovider}
            authProvider={authProvider}
            dashboard={Dashboard}
            disableTelemetry>
           
            <Resource name="user" {...users} icon={ReduceCapacityTwoToneIcon}/>           
            <Resource name='training' {...trainings} icon={ModelTrainingIcon}/>
            <Resource name='rule' {...rules} icon={Gavel}/>
            <Resource name='rlab/template' options={{ label: 'Template' }} {...template} icon={TabUnselectedIcon}/>
            <Resource name='timezone' {...timezone} icon={AccessTimeIcon}/> 
            <Resource name='logTask' options={{ label: 'Logs ' }} {...logTask} icon={AssignmentIcon}/> 
          </Admin>

     
    </StylesProvider>
  );
}


export default App;


import { Loading } from 'react-admin';
import Loadable from 'react-loadable';


const RuleList = Loadable({ loader: () => import('./ruleList'), loading: Loading });
const RuleCreate = Loadable({ loader: () => import('./ruleCreate'), loading: Loading });
const RuleEdit = Loadable({ loader: () => import('./ruleEdit'), loading: Loading });
// eslint-disable-next-line
export default {   
  list: RuleList,
  create: RuleCreate,
  edit: RuleEdit,
};
import { Loading } from 'react-admin';
import Loadable from 'react-loadable';


const UserList = Loadable({ loader: () => import('./userList'), loading: Loading });
const Usershow = Loadable({ loader: () => import('./userShow'), loading: Loading });
const UserCreate = Loadable({ loader: () => import('./userCreate'), loading: Loading });
const UserEdit = Loadable({ loader: () => import('./userEdit'), loading: Loading });

export default { 
  list: UserList,
  show: Usershow,
  create: UserCreate,
  edit: UserEdit,
};